import React, { useEffect, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './index.scss';
import './tier-list.scss';
import '../generic-page.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link, graphql } from 'gatsby';
import lodash from 'lodash';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ISoloWeapon } from '../../modules/common/model/graphql-types';
import { FilterBar } from '../../modules/cs/common/components/filter-bar/filter-bar';
import { Accordion, Button, Card } from 'react-bootstrap';
import { SoloWeapon } from '../../modules/solo/common/components/solo-weapon';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

interface ISoloWeaponNodes {
  nodes: ISoloWeapon[];
}

interface ISoloWeaponEntry {
  allCharacters: ISoloWeaponNodes;
}

interface IProps {
  data: ISoloWeaponEntry;
}

const SoloTierListWeapon: React.FC<IProps> = ({ data }) => {
  const [visibleWeapons, setVisibleWeapons] = useState(
    data.allCharacters.nodes
  );
  const [activeFilters, setActiveFilters] = useState(null);
  const [sortOption, setSortOption] = useState(null);

  const filterConfig = {
    filters: [
      {
        key: 'searchText',
        type: 'search',
        placeholder: 'Search weapons...'
      },
      {
        key: 'rarity',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          { label: 'R', value: 'R' },
          { label: 'SR', value: 'SR' },
          { label: 'SSR', value: 'SSR' }
        ]
      },
      {
        key: 'element',
        type: 'button_bar',
        values: [
          {
            value: 'all',
            image: <FontAwesomeIcon icon={faAsterisk} width="18" />
          },
          {
            value: 'Water',
            tooltip: 'Water',
            image: (
              <StaticImage
                src="../../images/solo/icons/ele_water.webp"
                width={24}
                alt="Water"
              />
            )
          },
          {
            value: 'Wind',
            tooltip: 'Wind',
            image: (
              <StaticImage
                src="../../images/solo/icons/ele_wind.webp"
                width={24}
                alt="Wind"
              />
            )
          },
          {
            value: 'Fire',
            tooltip: 'Fire',
            image: (
              <StaticImage
                src="../../images/solo/icons/ele_fire.webp"
                width={24}
                alt="Fire"
              />
            )
          },
          {
            value: 'Light',
            tooltip: 'Light',
            image: (
              <StaticImage
                src="../../images/solo/icons/ele_light.webp"
                width={24}
                alt="Light"
              />
            )
          },
          {
            value: 'Dark',
            tooltip: 'Dark',
            image: (
              <StaticImage
                src="../../images/solo/icons/ele_darkness.webp"
                width={24}
                alt="Dark"
              />
            )
          },
          {
            value: 'None',
            tooltip: 'None',
            image: (
              <StaticImage
                src="../../images/solo/icons/ele_none.webp"
                width={24}
                alt="None"
              />
            )
          }
        ]
      },
      { key: 'reset', type: 'reset' }
    ],
    defaults: null
  };
  const handleFilterUpdates = (choices) => {
    setActiveFilters({
      ...choices
    });
  };
  useEffect(() => {
    let filtered = data.allCharacters.nodes;

    if (activeFilters) {
      if (activeFilters.searchText) {
        filtered = filtered.filter(
          (emp) =>
            emp.name
              .toLowerCase()
              .indexOf(activeFilters.searchText.toLowerCase()) > -1
        );
        filtered = lodash.uniqBy(filtered, 'unitId');
      }
      if (activeFilters.rarity && activeFilters.rarity !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.rarity.toLowerCase() === activeFilters.rarity.toLowerCase()
        );
      }
      if (activeFilters.type && activeFilters.type !== 'all') {
        filtered = filtered.filter(
          (emp) => emp.type.toLowerCase() === activeFilters.type.toLowerCase()
        );
      }
      if (activeFilters.element && activeFilters.element !== 'all') {
        filtered = filtered.filter(
          (emp) =>
            emp.element.toLowerCase() === activeFilters.element.toLowerCase()
        );
      }
    }

    if (sortOption === '+fullName') {
      filtered = filtered.sort((a, b) => (a.rarity < b.rarity ? 1 : -1));
    } else if (sortOption === '-fullName') {
      filtered = filtered.sort((a, b) => (a.name < b.name ? 1 : -1));
    }

    setVisibleWeapons(filtered);
  }, [activeFilters, data.allCharacters.nodes, sortOption]);

  const sortingOptions = [
    { value: '+fullName', label: 'Sort by A-Z' },
    { value: '-fullName', label: 'Sort by Z-A' }
  ];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sortChangeHandler = (e) => {
    setSortOption(e.value);
  };

  let defaultSortOption = sortOption
    ? sortingOptions.find((opt) => opt.value === sortOption)
    : null;
  if (defaultSortOption === null) {
    defaultSortOption = sortingOptions[0];
    setSortOption(defaultSortOption.value);
  }
  return (
    <DashboardLayout className={'generic-page solo solo-tier'} game="solo">
      <ul className="breadcrumb">
        <li>
          <Link to="/solo-leveling/">Solo Leveling: Arise</Link>
        </li>
        <li className="divider">/</li>
        <li>Weapon Tier List</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/solo/categories/category_tier2.jpg"
            alt="tier"
          />
        </div>
        <div className="page-details">
          <h1>Solo Leveling: Arise Weapon Tier List</h1>
          <h2>
            Our Solo Leveling: Arise Weapon Tier List rates all weapons
            available to Sung Jinwoo by their performance and potential.
          </h2>
          <p>
            Last updated: <strong>30/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader title="Solo Leveling: Arise Weapon Tier List" />
      <p className="intro">
        The tier list rates Sung Jingwoo weapons based on their performance
        across all PVE content - it tries to average how they perform against
        bosses and in normal stages.
      </p>
      <p className="intro">Special markers:</p>
      <ul className="intro">
        <li>
          SSR Weapons that have <strong className="marks">A?</strong> shown in
          the top right corner of their icons, it shows the suggested Ascension
          level for that weapon to be considered the tier we placed them in - if
          you have less dupes than indicated, you can drop them down a tier. If
          there's no special indicator, then the weapon is rated at A0 so with 0
          dupes.
        </li>
      </ul>
      <Accordion className="tier-list-accordion">
        <Accordion.Item eventKey="3">
          <Accordion.Header>Ratings explained</Accordion.Header>
          <Accordion.Body>
            <ul className="no-margin">
              <li>
                <strong>SS</strong> - The weapons in the SS Tier are revered for
                their undeniable power and versatility. These weapons are not
                only flexible but can also serve as the cornerstone of your
                account, no matter the fight. With the introduction of Guild
                Boss mode, the importance of weapons that can support the entire
                team through powerful buffs or debuffs has significantly
                increased. Among these, Moonshadow stands out, arguably the
                strongest weapon in the game overall. Its ability to buff all
                team members at A5 makes it a crucial asset, especially for
                boosting your Guild Boss and PoD scores. Given its immense
                value, it's advisable to keep Moonshadow in your rate-up to
                achieve that highly coveted power spike. However, it’s currently
                rated at A3, with a $ tag denoting its value.
              </li>
              <li>
                <strong>S+</strong> - Weapons in the S+ Tier are top-tier when
                it comes to DPS within their respective elements. They offer
                incredible value and should be seriously considered when
                curating your wishlist. West Wind remains a phenomenal weapon
                for progression, but it has been slightly downgraded when
                factoring in Guild Boss considerations. The Truth: Demon Light
                Spear is a notable mention based on its expected performance and
                since it can be obtained for free from the Guild Store, we will
                be rating it at an A5 requirement, similar to other free weapons
                in the list. Depending on the level of your Radiru Bow, it can
                serve as a viable replacement until you secure a few dupes of
                the Demon Light Spear.
              </li>
              <li>
                <strong>S</strong> - Weapons in the S Tier are strong options
                within their respective elements, but they possess certain flaws
                that prevent them from reaching the highest tier. With recent
                changes to the S+ rank, several popular weapons, including
                Scythe, Radiru Bow, and Orb of Avarice, have been reclassified
                into this tier. Typically, SSR weapons will fall into this
                ranking, especially freebies, unless they prove to be slightly
                underwhelming compared to other general-use options.
              </li>
              <li>
                <strong>A</strong> - strong weapons that perform well that still
                have place in the meta, but require a bit more work to shine.
              </li>
              <li>
                <strong>B</strong> - average weapons that have big flaws and
                only work as some niche replacements when you don't have
                anything else.
              </li>
              <li>
                <strong>C</strong> - below average weapons that are struggling
                to find their place in the meta.
              </li>
              <li>
                <strong>D</strong> - underwhelming weapons that aren't worth
                using.
              </li>
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Changelog</Accordion.Header>
          <Accordion.Body>
            <h5>30.11.2024</h5>
            <ul>
              <li>Added new weapons and updated old weapons ratings.</li>
            </ul>
            <h5>13.10.2024</h5>
            <ul>
              <li>Demon King Longsword: S to S+</li>
              <li>West Wind: S+ to S</li>
              <li>Demon King Daggers: Added to S+ (A3)</li>
            </ul>
            <p>
              Comments to the changes: After some testing, we can say that West
              Wind is no longer the Wind king, but remains solid enough for
              people that didn't invest early in the Demon King Longsword. At
              A3, both the Longsword and the Daggers outperform the West Wind
              with no need for stacking and potentially losing out damage. The
              Dagger, when maxed at A5, should output more consistent damage but
              has to land the skill - this could become a bit of an issue on
              mobile, where the game is not as responsive. Meanwhile, the
              Longsword is very easy to use, but keep in mind you will need to
              properly counter to get the most out of the weapon, so depending
              on the fight you may find one weapon works best compared to the
              other. As there's no clear 'winner', we decided to rank both of
              them in the same spot.
            </p>
            <h5>28.08.2024</h5>
            <p>Revamped the tier list by introducing new tiers.</p>
            <h5>08.08.2024</h5>
            <p>
              We have added the <strong>Secured Marlin Surfboard</strong> to the
              tier list.
            </p>
            <h5>15.07.2024</h5>
            <p>
              We have added the <strong>Phoenix Soul</strong> and fully updated
              the tier list.
            </p>
            <h5>03.06.2024</h5>
            <ul>
              <li>
                We have updated the tier list after further testing the buffed
                weapons,
              </li>
              <li>
                <strong>[Shadow Scythe]</strong> from S to S+. We undervalued
                the power of Schyte, especially for newer players considering
                how impactful getting a guaranteed dupe is. It's a strong weapon
                that can be slot in many setups, so we feel it deserved the
                overall bump.
              </li>
              <li>
                <strong>[Vulcan's Rage]</strong> from A to S. The latest buff
                made the weapon VERY good, especially if you can get one dupe in
                it. At A0 it's still very serviceable.
              </li>
              <li>
                <strong>[West Wind]</strong>. Despite the nerf, West Wind
                remains one of the best weapons in the game even at A3 (the
                threshold we are evaluating SR weapons) and remains insane at A5
                for the powerful crit abuse.
              </li>
              <li>
                <strong>[Demon King Greatsword]</strong> The weapon received
                another powerful buff. We are monitoring the weapon's
                performance closely, as we feel the weapon is very close to the
                S+ tier now.
              </li>
              <li>
                <strong>[Skadi]</strong> As mentioned, the weapon is a very
                powerful addition to Water setups. A decent part of his power is
                gated behind dupes, and at A0 it's less universal compared to
                Thethis Grimoire. The same logic applies to Moonshadow - that
                however only needs A1 to jump to S+ no question asked.
              </li>
            </ul>
            <h5>30.05.2024</h5>
            <ul>
              <li>
                Added <strong>[Skadi]</strong> to the tier list.
              </li>
              <li>
                We managed to test Skadi a bit and while the weapon's damage
                output is undeniable - especially if you can get a few copies of
                it for the advancement bonuses - we feel the overall value is a
                bit below the Book of Thethis, considering it's universally
                useful and pairs with almost every weapon. Skadi remains an
                amazing second weapon to pair with book, similar to Moonshadow
                for the Dark element.
              </li>
            </ul>
            <h5>02.05.2024</h5>
            <p>Initial release of the tier list.</p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="banner solo-promo">
        <h4>Play and reroll in Solo Leveling: Arise on PC</h4>
        <OutboundLink href="https://bstk.me/t6T4etbQ4" target="_blank">
          <Button variant="primary">Play now on BlueStacks</Button>
        </OutboundLink>
      </div>
      <div className="tier-list-header">
        <p className="title">
          Tier List <span>(PVE)</span>
        </p>
        <p className="sub-title">
          You're currently viewing the <strong>PVE</strong> tier list. It shows
          how the weapons performs in Story in both boss oriented and generic
          content.
        </p>
      </div>
      <div className="employees-filter-bar solo">
        <FilterBar config={filterConfig} onChange={handleFilterUpdates} />
      </div>
      <div className="tier-list-page nikke">
        <div className="tier">
          <div className="tier-rating ss">SS</div>
          <div className="tier-container">
            {visibleWeapons
              .filter((emp) => emp.type === 'Sung')
              .filter((emp) => emp.ratings.pve === 10)
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((emp, index) => {
                return (
                  <Card className="avatar-card" key={index}>
                    <SoloWeapon name={emp.name} mode="icon" showLabel />
                  </Card>
                );
              })}
          </div>
        </div>
        <div className="tier">
          <div className="tier-rating s-plus">S+</div>
          <div className="tier-container">
            {visibleWeapons
              .filter((emp) => emp.type === 'Sung')
              .filter((emp) => emp.ratings.pve === 9.5)
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((emp, index) => {
                return (
                  <Card className="avatar-card" key={index}>
                    <SoloWeapon name={emp.name} mode="icon" showLabel />
                  </Card>
                );
              })}
          </div>
        </div>
        <div className="tier">
          <div className="tier-rating s">S</div>
          <div className="tier-container">
            {visibleWeapons
              .filter((emp) => emp.type === 'Sung')
              .filter((emp) => emp.ratings.pve === 9)
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((emp, index) => {
                return (
                  <Card className="avatar-card" key={index}>
                    <SoloWeapon name={emp.name} mode="icon" showLabel />
                  </Card>
                );
              })}
          </div>
        </div>
        <div className="tier">
          <div className="tier-rating a">A</div>
          <div className="tier-container">
            {visibleWeapons
              .filter((emp) => emp.type === 'Sung')
              .filter((emp) => emp.ratings.pve === 8)
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((emp, index) => {
                return (
                  <Card className="avatar-card" key={index}>
                    <SoloWeapon name={emp.name} mode="icon" showLabel />
                  </Card>
                );
              })}
          </div>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297241"></div>
        <div className="tier">
          <div className="tier-rating b">B</div>
          <div className="tier-container">
            {visibleWeapons
              .filter((emp) => emp.type === 'Sung')
              .filter((emp) => emp.ratings.pve === 7)
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((emp, index) => {
                return (
                  <Card className="avatar-card" key={index}>
                    <SoloWeapon name={emp.name} mode="icon" showLabel />
                  </Card>
                );
              })}
          </div>
        </div>
        <div className="tier">
          <div className="tier-rating c">C</div>
          <div className="tier-container">
            {visibleWeapons
              .filter((emp) => emp.type === 'Sung')
              .filter((emp) => emp.ratings.pve === 6)
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((emp, index) => {
                return (
                  <Card className="avatar-card" key={index}>
                    <SoloWeapon name={emp.name} mode="icon" showLabel />
                  </Card>
                );
              })}
          </div>
        </div>
        <div className="tier">
          <div className="tier-rating d">D</div>
          <div className="tier-container">
            {visibleWeapons
              .filter((emp) => emp.type === 'Sung')
              .filter((emp) => emp.ratings.pve === 5)
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((emp, index) => {
                return (
                  <Card className="avatar-card" key={index}>
                    <SoloWeapon name={emp.name} mode="icon" showLabel />
                  </Card>
                );
              })}
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297241"></div>
    </DashboardLayout>
  );
};

export default SoloTierListWeapon;

export const Head: React.FC = () => (
  <Seo
    title="Weapon Tier List | Solo Leveling: Arise | Prydwen Institute"
    description="Our Solo Leveling: Arise Weapon Tier List rates all weapons available to Sung Jinwoo by their performance and potential. Find the best weapons for Sung Jingwoo with our tier list!"
    game="solo"
  />
);

export const pageQuery = graphql`
  query {
    allCharacters: allContentfulSoloWeapon(sort: { fields: name, order: ASC }) {
      nodes {
        id
        unitId
        name
        type
        rarity
        investment
        element
        ratings {
          pve
        }
      }
    }
  }
`;
